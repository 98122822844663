// Convert moment.js to our own version, because The Events Calendar's version has a conflict.
// We can't remove that version without messing up other things, so this is a hack.
window.sp_moment = window.moment;

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // hyperlinked images shouldn't have underlines.
        $('.content a > img').closest('a').addClass('no-underline');

        // Check for click events on the navbar burger icon
        $(".navbar-control").click(function() {

            var $this = $(this);
            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            $this.toggleClass("is-active");
            $this
              .closest(".navbar")
              .find(".navbar-menu")
              .slideToggle( 500, function() {
                  $(this).toggleClass("is-active").css("display", "");
                }
              );

        });

        // Use Moment and Moment Timezones (installed via bower) to display times in current timezone.
        function update_event_timezones() {
          if (
            ( 'undefined' === typeof window.sp_moment )
            || ( 'undefined' === typeof window.sp_moment.tz )
            ) {
            return;
          }

          var current_timezone_location = sp_moment.tz.guess(); // "America/New York"
          var current_timezone = sp_moment.tz(current_timezone_location).format('z'); // "EST"

          // convert any times that need converting
          // format: <time datetime="2020-07-13T15:00:00-04:00" data-format="h:mma">Original time</time>

          $('.time-localize').each(function() {
            var $this = $(this);
            var format = $this.data('format');
            var datetime = $this.attr('datetime');

            if ( ( 'undefined' == typeof format ) || !format ) { return; }
            if ( ( 'undefined' == typeof datetime ) || !datetime ) { return; }

            var formatted_time = sp_moment(datetime).tz(current_timezone_location).format(format);
            if ( formatted_time ) {
              $this.html(formatted_time).addClass("moment-formatted");
            }

          });

          // fix timezone abbreviations (e.g., "EDT")
          $('.timezone-localize').html(current_timezone);
        }

        // update timezones now
        $(document).ready(update_event_timezones);

        // attach to event ajax updates
        $(document).ajaxComplete(update_event_timezones);

        // Upcoming events pop-out
        var upcomingEventsBtn = document.querySelector('.upcoming-events__button');
        var upcomingEventsCloseBtn = document.querySelector('.upcoming-events__close-button');
        var upcomingEventsActiveClass = 'upcoming-events-visible';
        if (upcomingEventsBtn) {
          upcomingEventsBtn.addEventListener('click', function(evt) {
            if (document.body.classList.contains(upcomingEventsActiveClass)) {
              document.body.classList.remove(upcomingEventsActiveClass);
            } else {
              document.body.classList.add(upcomingEventsActiveClass);
            }
            evt.preventDefault();
          });
        }
        if (upcomingEventsCloseBtn) {
          upcomingEventsCloseBtn.addEventListener('click', function(evt) {
            document.body.classList.remove(upcomingEventsActiveClass);
            evt.preventDefault();
          });
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // ------------------------------------------------
        // Make embedded videos responsive
        // ------------------------------------------------

        $("main iframe, main object, main embed").each(function() {
          var $this = $(this);
          var id = $this.attr('id');
          if ($this.is(':hidden')) { return; }
          if ($this.closest('.embed-container').length) { return; }
          if ($this.closest('.map-container').length) { return; }
          if (id && id.startsWith('advanced_iframe')) { return; }
          if ($this.closest('.CnP_inlineform').length) { return; }
          $this.prop('height', '').prop('width', '').wrap('<div class="embed-container"></div>');
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $slick_slider = $('#home-hero-slideshow');
        settings = {
          arrows : false,
          autoplay: true,
          fade: true,
          dots: false,
          draggable: false,
          pauseOnFocus: false,
          pauseOnHover: false,
          swipe: false,
          speed: 1500,
          autoplaySpeed: 3000,
          rows: 0

        }
        $slick_slider.slick(settings);

        // reslick only if it's not slick()
        $(window).on('resize', function() {
          if ($(window).width() < 768) {
            if ($slick_slider.hasClass('slick-initialized')) {
              $slick_slider.slick('unslick');
            }
            return;
          }
          if (!$slick_slider.hasClass('slick-initialized')) {
            return $slick_slider.slick(settings);
          }
        });


        // https://stackoverflow.com/questions/47638344/muted-autoplay-in-chrome-still-not-working
        // $('video').on('canplaythrough', function() {
        //     var this = $(this)[0];
        //     this.muted = true;
        //     // this.play();
        // });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page': {
      init: function() {

      },
      finalize: function() {

        // pass the current location ID to the events calendar AJAX; the ID is inserted by WP footer action
        if ( ( 'undefined' !== typeof window.tribe_ev ) && ( 'undefined' !== typeof window.seniorplanet_location ) ) {

          if ( ( 'undefined' === typeof tribe_ev.data.params ) || !tribe_ev.data.params ) {
            tribe_ev.data.params = '';
          } else {
            tribe_ev.data.params += '&';
          }

          tribe_ev.data.params += 'location=' + window.seniorplanet_location;

          $( window.tribe_ev.events ).on( 'tribe_ev_collectParams', function() {
            if ( -1 === tribe_ev.state.params.indexOf( 'location=' ) ) {
              tribe_ev.state.params += '&location=' + window.seniorplanet_location;
            }
            if ( -1 === tribe_ev.state.url_params.indexOf( 'location=' ) ) {
              tribe_ev.state.url_params += '&location=' + window.seniorplanet_location;
            }
          });
        }
      }


    },

    'single_course': {
      init: function() {
        $('.single-course-locations-listing-meta-control').on('click', function(e) {
          e.preventDefault();
          var $this = $(this);

          $this.closest('.single-course-locations-listing').toggleClass('expanded').find('.single-course-locations-listing-meta').slideToggle();

          // swap out the text
          var text = $this.data('text');
          if (text) {
            $this.data('text', $this.html());
            $this.html(text);
          }

          $this.toggleClass('is-inactive');

        });
      }
    },

    'single_post': {
      init: function() {

      }
    }


  };




  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function parseParams(str) {
    return str.split('&').reduce(function (params, param) {
        var paramSplit = param.split('=').map(function (value) {
            return decodeURIComponent(value.replace(/\+/g, ' '));
        });
        params[paramSplit[0]] = paramSplit[1];
        return params;
    }, {});
}

document.addEventListener('DOMContentLoaded', function() {
  function videoPlaceholders() {
    var buttons = document.querySelectorAll('.video-wrap__button');
    if (!buttons) {
      return;
    }
    function showVideoIframe(evt) {
      var button = evt.currentTarget;
      var parent = button.closest('.video-wrap__media');
      if (!parent) {
        return;
      }
      var placeholder = parent.querySelector('.video-wrap__placeholder');
      var iframe = parent.querySelector('iframe');
      if (!placeholder || !iframe) {
        return;
      }

      // Hide placeholder and button before showing the iframe
      placeholder.style.display = 'none';
      button.style.display = 'none';
      iframe.style.display = 'block';

      // Ensure the iframe URL has autoplay=1
      var currentSrc = iframe.src;
      if (!currentSrc.includes('autoplay=1')) {
        iframe.src += '&autoplay=1';
      }

    }
    buttons.forEach(function(button) {
      button.addEventListener('click', showVideoIframe, false);
    });
  }
  videoPlaceholders();
});
